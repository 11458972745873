* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif !important;
  height: 100%;
  background-color: #f5f5f5 !important;
  letter-spacing: 0.5px;
  font-size: 14px;
}

body {
  font-size: 14px;
  color: #4c5258;
  letter-spacing: 0.5px;
  font-family: "Poppins", sans-serif !important;
  background-color: #f5f5f5 !important;
  overflow-x: hidden;
}

.wrapper {
  width: auto;
  position: relative;
}

a {
  text-decoration: none;
}

::placeholder {
  opacity: 0.9 !important;
}

#Overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(211 209 209 / 50%);
  z-index: 4999;
}

.LoderImg {
  width: 5rem;
  position: fixed;
  top: 45%;
  z-index: 10000;
  display: none;
  left: 50%;
}

.reactalert-danger {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
  bottom: 1% !important;
  right: 1% !important;
  z-index: 100 !important;
  display: block !important;
  text-align: center !important;
  position: fixed !important;
  z-index: 2147483642 !important;
  padding: 1rem 3rem 1rem 3rem !important;
}

.reactalert-success {
  color: #0a5632 !important;
  background-color: #c6dfd4 !important;
  border-color: #badbcc !important;
  bottom: 1% !important;
  right: 1% !important;
  z-index: 100 !important;
  display: block !important;
  text-align: center !important;
  position: fixed !important;
  z-index: 2147483642 !important;
  padding: 1rem 3rem 1rem 3rem !important;
}

.alert-success {
  color: #0a5632 !important;
  background-color: #c6dfd4 !important;
  border-color: #badbcc;
  bottom: 1%;
  right: 1%;
  display: none;
  text-align: center;
  position: fixed;
  z-index: 2147483642;
  padding: 1rem 3rem 1rem 3rem !important;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  bottom: 1%;
  right: 1%;
  z-index: 100;
  display: none;
  text-align: center;
  position: fixed !important;
  z-index: 2147483642;
  padding: 1rem 3rem 1rem 3rem !important;
}

/* 
.w3-animate-right {
  animation: animateright 0.4s;
  position: fixed !important;
} */

/* Page Content */

.page-content {
  margin-left: 250px;
  margin-top: 50px;
  padding: 1.2rem;
  transition: all 0.2s;
}

.PageHeading {
  font-weight: 600;
  font-size: 22px;
  /* color: #030e20; */
  color: #030e20ab;
}

.BatchNameHeading {
  font-weight: 600;
  font-size: 16px;
  color: #030e20ab;
}

.GridBox {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.GreyGridBox {
  background: #f3f3f3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.gridsearchbar {
  position: relative;
}

.GridHeader {
  color: black;
  font-weight: 600;
  font-size: 15px;
  padding: 0.5rem 0.5rem;
}

.gridsearchbar .form-control {
  border-radius: 10px;
  box-shadow: none;
  padding-left: 2.5rem;
  padding-right: 0.5rem;
  border: 1px solid #e2e3e4;
}

.GridTitle {
  font-weight: bold;
  font-size: 18px;
  color: #030e20 !important;
}

.searchIcon {
  opacity: 0.5 !important;
}

.form-check-input:checked {
  background-color: #3cbba5 !important;
  border-color: #3cbba5 !important;
}

.BgPink {
  background-color: #ff7076 !important;
  border: 1px solid #ff7076 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.lowOpacityColor {
  color: rgb(21 24 33 / 33%);
}

select {
  border: 1px solid #e2e3e4 !important;
  border-radius: 10px !important;
  padding: 5px !important;
  width: 100% !important;
}

.greyBox {
  background: #f7f8fa;
  border-radius: 10px;
  padding: 10px;
}

.SaveBtn {
  background: #3cbba5;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border: 1px solid #3cbba5;
  color: white;
  padding: 6px 10px;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 4px !important;
}

.Cancel-btn {
  background-color: white;
  border: #f10d0d 1px solid;
  color: #f10d0d;
  padding: 6px 10px;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 4px !important;
}

.deleteBtn {
  background: #ff7076;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border: 1px solid #ff7076;
  color: white;
  padding: 6px 10px;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 4px !important;
}

.CreateButton {
  background: #3cbba5;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border: 1px solid #3cbba5;
  color: white;
  padding: 10px;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 20px !important;
}

.SaveBtn:hover {
  background: #16ad93;
  color: white !important;
  border: 1px solid #16ad93;
}

.Cancel-btn:hover {
  background: #f10d0d;
  color: white;
  border: 1px solid #f10d0d;
}

.deleteBtn:hover {
  background: white;
  color: #ff7076 !important;
  font-weight: 600;
}

.grid {
  padding: 0.5rem 0.5rem;
  font-size: 13px;
}

.grid:nth-child(even) {
  background: #ffffff;
}

.grid:hover {
  background-color: #f7fffe;
}

.ImportInventoryBtn {
  background: #f9fafb;
  border: 1px solid #e2e3e4;
  border-radius: 15px 0px 0px 15px;
  padding: 8px;
}

.ImportInventoryBtnForimportExport {
  background: white;
  border: 1px solid #e2e3e4;
  border-radius: 15px 0px 0px 15px;
  padding: 8px;
}

.ImportInventoryBtnForAdminInvoice {
  background: #f9fafb;
  border: 1px solid #e2e3e4;
  border-radius: 15px 15px 15px 15px;
  padding: 8px;
}

.ImportInventoryBtn1 {
  background: #3cbba5;
  border: 1px solid #e2e3e4;
  border-radius: 0px 15px 15px 0px;
  padding: 8px;
  /* width: 10px; */
  height: 40px;
}

.BorderBtn {
  background: #f9fafb;
  border: 1px solid #e2e3e4;
  border-radius: 10px;
  padding: 5px 13px 5px 13px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
}

.BorderBtnForimportExport {
  background: white;
  border: 1px solid #e2e3e4;
  border-radius: 15px;
  padding: 8px 13px 8px 13px;
  cursor: pointer;
  margin-right: 10px;
}

.UploadBtn {
  background: white;
  border: 1px solid #e2e3e4;
  border-radius: 0px 15px 15px 0px;
  padding: 8px;
}

.UploadCSVBtn {
  background: white;
  border: 1px solid #e2e3e4;
  border-radius: 15px;
  padding: 8px;
}

input[type="file"] {
  display: none;
}

.side-menu-scroll-style {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: transparent transparent;
  /* For Firefox */
}

.side-menu-scroll-style::-webkit-scrollbar {
  width: 0 !important;
}

.side-menu-scroll-style {
  scrollbar-width: 0 !important;
  -ms-overflow-style: none !important;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #bbbcc1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbbcc1;
}

.GridNoRecord {
  padding: 10px;
  border-bottom: 1px solid #efe4e4;
}

.form-check-input:focus {
  border-color: #3cbba5;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(217 227 225) !important;
}

.BlackFont {
  color: black !important;
}

.WhiteFont {
  color: white;
}

.bgWhite {
  background-color: white !important;
}

.SaveBtn a {
  color: white;
}

.SaveBtn a:hover {
  color: #3cbba5;
}

.deleteBtn a {
  color: white;
}

.deleteBtn a:hover {
  color: #ff7076;
}

.FormLabel {
  font-size: 13px;
  color: #4c5258;
}

.form-control {
  border: 1px solid #e2e3e4 !important;
  border-radius: 0.3rem !important;
}

.SuggestionBoxDiv {
  background: #f9fafb;
  border: 1px solid #e2e3e4;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  color: #4c5258;
  transition: 0.3s;
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  height: 315px;
  transition: visibility 0s, opacity 0.3s linear;
}

.SuggestionBoxDiv div {
  padding: 2px;
}

.SuggestionBoxDiv div:hover {
  background: #ffffff;
  border-radius: 3px;
  cursor: pointer;
}

.brdr-Btm {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.fw-600 {
  font-weight: 600;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: white !important;
  border-top-color: #2cc3a9 !important;
  border-left-color: #2cc3a9 !important;
  border-right-color: #2cc3a9 !important;
  font-weight: 600;
  border-radius: 10px 10px 0px 0px !important;
  background-color: #2cc3a9 !important;
  padding: 12px !important;
  border: none;
  /* border-bottom: 1px solid #2cc3a9 !important; */
}

.TabNavlink {
  color: #9ea4aa !important;
  padding: 12px !important;
  /* border-bottom: 1px solid #f7f8fa !important; */
  border: 1px solid #e5e5e5 !important;
}

.nav-link {
  font-weight: 600 !important;
}

.nav-tabs {
  border-bottom: none !important;
}

.customFormControl {
  border-radius: 30px !important;
  padding: 0.6rem 0.75rem !important;
}

.redText {
  color: red;
}

.grayColor {
  color: #4c525880;
}

.greenText {
  color: green;
}

.modal-top-margin {
  margin-top: 150px;
}

div.even {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 65px;
  display: flex;
  align-items: center;
}

div.odd {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 65px;
  display: flex;
  align-items: center;
}

div.editpart-style {
  height: 65px;
  display: flex;
  align-items: center;
}

.scrollbar-css {
  max-height: 370px;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.scrollbar-css-repairdetails {
  max-height: 500px;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.scrollbar-css-ticketform {
  max-height: 500px;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.modal .modal-content {
  -webkit-animation-name: modal-animation;
  -webkit-animation-duration: 0.5s;
  animation-name: modal-animation;
  animation-duration: 0.5s;
}

.modal-top-margin-style {
  margin-top: 50px;
}

.pagination {
  display: inline-block;
  margin-bottom: 0px !important;
}

.pagination a {
  color: black;
  float: left;
  padding: 0;
  text-decoration: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  width: 26px;
  height: 25px;
  line-height: 11px;
  font-size: 13px;
}

.pagination>li {
  padding: 10px;
}

.pagination>.MasterClass>a {
  border: none;
  padding-right: 10px;
}

.pagination>li.active>a {
  background: #030e20;
  color: #fff;
}

.pagination {
  justify-content: center;
}

.first {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.last {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.OddEvenClass {
  padding-top: 12px;
  padding-bottom: 12px;
  /* cursor: pointer; */
}

.OddEvenClass:nth-child(odd) {
  background-color: white;
}

.NewOddEvenClass {
  padding-top: 12px;
  padding-bottom: 12px;
  /* cursor: pointer; */
}

.NewOddEvenClass:nth-child(odd) {
  background-color: #f7f8fa;
}

.tableBorderHide {
  border-style: none !important;
  border-color: transparent;
}

.hoverstyle:hover {
  cursor: pointer;
}

.mousechange:hover {
  cursor: pointer;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.vl {
  border-left: 1px solid #c7c7c7;
}

.card {
  max-width: 41rem !important;
}

.greyBox-height {
  background: #f7f8fa;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}

.greyBox1 {
  background: #f7f8fa;
  border-radius: 10px;
  padding: 10px;
}

.greyBox-height1 {
  background: #f7f8fa;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}

input[type="checkbox"] {
  accent-color: #212529;
  border-color: #9f9e9e;
}

.checkbox-font {
  color: #4c5258 !important;
}

button.upload-save {
  float: right;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header {
  display: block;
}

.form-control.border-none {
  border: none !important;
}

.form-control.border-none:focus {
  box-shadow: none !important;
}

.form-control.border-none option {
  color: #000;
}

.OddEvenClass:nth-child(even) .border-none {
  background-color: transparent;
}

.attach-img-icon {
  height: 25px;
  text-align: center;
  width: 25px !important;
  display: block;
  line-height: 25px;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  background: #3cbba5;
}

.attach-img-close-icon {
  height: 16px;
  text-align: center;
  width: 16px !important;
  color: #fff;
  font-weight: 500;
  line-height: 14px;
  border-radius: 50%;
  font-size: 12px;
  background: #3cbba5;
  top: -10px;
  right: 0;
  cursor: pointer;
}

.nav-item.active {
  background-color: #f00;
  /* set your desired background color here */
  color: #fff;
  /* set the text color for the active tab here */
}

.Emailinvalid {
  font-size: 0.875em !important;
  color: #dc3545 !important;
}

@media screen and (max-width: 1300px) {

  .reponsiveGrid,
  .reponsiveGridForTechStaffmember {
    display: flex;
    overflow-x: auto;
  }

  .tableGrid,
  .tableGridForTechStaffmember>.grid {
    min-width: 900px;
  }
}

@media screen and (max-width: 900px) {
  .reponsiveGrid {
    display: flex;
    overflow-x: scroll !important;
  }

  .tableGrid>.grid {
    min-width: 900px;
  }
}

@media only screen and (max-width: 900px) {
  .marginCss {
    margin-top: 10px;
  }

  .cssFormobile {
    padding-left: 10px;
  }

  .buttonclssMobile {
    margin-top: 10px;
    border-radius: 15px;
  }

  .buttonclssMobile1 {
    border-radius: 15px !important;
    margin-right: 10px !important;
  }

  .paddingClassForMobile {
    padding-left: 10px;
    margin-top: 15px !important;
  }

  .brandNameClss {
    margin-top: 15px !important;
  }

  .NameStyle {
    padding-left: 0px !important;
  }
}

@media only screen and (max-width: 394px) {
  .marginForMobile {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 767px) {
  .marginDetailsPopup {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 365px) {
  .margintabmanageDevice {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 583px) {
  .margintabmanageDevice1 {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 620px) {
  .margintopformanageticket {
    margin-top: 10px !important;
  }
}

/* @media (min-width: 900px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
} */
.data-table-container {
  overflow-x: auto;
  max-width: 100%;
}

.data-table {
  width: auto;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
}

.scrollmobilestyle {
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
}

.data-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.data-table tbody tr:nth-child(even) {
  background-color: transparent;
}

.Leftmenulogo {
  max-width: 80% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
  object-fit: contain;
}

.location-name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.address {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.note-wrap {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.faq-title {
  color: rgba(21, 24, 33, 0.57);
  font-weight: 700;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-content {
  border: 1px solid #e2e2e5;
  padding: 20px;
  overflow: hidden;
}

.Chatboat {
  border: 1px solid #d1cece;
  padding: 3px;
}

.Chatboatheading {
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #efeeee;
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.schoolColorStatus {
  color: #3cbba5;
}

/* login css */
.card {
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%) !important;
  border: none !important;
  background-color: #ffffff !important;
  max-width: 30rem;
  border-radius: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  /* position: absolute; */
  top: 45%;
  transform: translateY(-45%);
  text-align: center;
}

.TicketForm {
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%) !important;
  border: none !important;
  background-color: #ffffff !important;
  border-radius: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  top: 45%;
  transform: translateY(-45%);
  text-align: center;
}

.signuplink {
  text-decoration: none;
  color: #3bbba4;
}

.VerticalTop {
  position: relative;
  width: 100%;
  top: 200px;
  border: 1px solid #d3c7c7;
  border-radius: 10px;
}

.MainDiv {
  background-size: cover;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.MainDiv-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Or any other desired fit */
}

.LowOpacityColor {
  color: rgba(21, 24, 33, 0.25);
}

.MicrosoftGoogleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000 !important;
  padding: 10px 25px !important;
  box-shadow: none !important;
  border: 1px solid #e2e3e4 !important;
  font-size: 13px !important;
  background: #f9fafb !important;
  border-radius: 16px !important;
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
  width: 100% !important;
}

.MicrosoftGoogleBtn span {
  border-radius: 10px !important;
  border-left: 2px solid #ff3d00;
  border-top: 2px solid #ffc107;
  border-bottom: 2px solid #1976d2;
  border-right: 2px solid #4caf50;
  border-image-slice: 1;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: 600;
}

.ImportContactsGoogleBtn {
  color: #000000 !important;
  padding: 10px !important;
  box-shadow: none !important;
  border: 1px solid #e2e3e4 !important;
  font-size: 13px !important;
  background: white !important;
  border-radius: 16px !important;
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
  width: 100% !important;
}

/* login css end */
.termsandconditionheaderstyle {
  background-color: #2cc3a9;
}

.sortbyClass {
  height: 13px !important;
}

.error-image {
  background-size: cover;
  max-width: 100%;
  background-repeat: no-repeat;
  position: relative;
}

.cardForLogOut {
  max-width: 30rem;
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  border: none;
  background-color: #ffffff;
  border-radius: 20px;
  text-align: center;
  padding: 20px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .cardForLogOut {
    max-width: 90%;
  }
}

@media (max-width: 576px) {
  .cardForLogOut {
    padding: 10px;
    border-radius: 10px;
  }
}

.p-Suggestion {
  padding: 8px 15px !important;
}

.accordion-item:last-of-type .accordion-button {
  color: #15182191 !important;
  font-weight: 600 !important;
}

.accordion-collapse .collapse .show {
  color: #15182191 !important;
  font-weight: 600 !important;
}

.accordion-button:not(.collapsed) {
  color: #15182191 !important;
  font-weight: 600 !important;
  background-color: #f0f0f1 !important;
  border: none;
}

.accordion-button {
  color: #15182191 !important;
  font-weight: 600 !important;
  background-color: #f0f0f1 !important;
}

.bs-accordion-btn-focus-box-shadow {
  border: none;
}

.profile-icon {
  width: 35px;
  height: 35px;
  background-color: #151821;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  font-weight: 600;
}

.initial {
  font-weight: bold;
}

.CustomMicrosoftBtn {
  color: #000000 !important;
  padding: 10px 75px !important;
  box-shadow: none !important;
  border: 1px solid #e2e3e4 !important;
  font-size: 13px !important;
  background: #f9fafb !important;
  border-radius: 30px !important;
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
  width: 100% !important;
  display: flex;
  align-items: center;
}

.CustomMicrosoftBtn img {
  height: 20px;
  width: 20px;
}

.CustomMicrosoftBtn:hover {
  background-color: #e2e3e4;
}

@keyframes dots {
  0% {
    content: ".";
  }

  33% {
    content: "..";
  }

  66% {
    content: "...";
  }

  100% {
    content: ".";
  }
}

/* .loading-dots:after {
  content: "...";
  animation: dots 1s infinite;
} */

.grayborderbox {
  border: 2px gray solid;
  padding: 20px;
  border-radius: 15px;
}

.textstyleforcards {
  font-size: 15px;
  font-weight: 600;
  color: #4c5258;
}

.textstyleforcards1 {
  font-size: 15px;
  font-weight: 400;
  color: black;
}

.inline-label {
  display: inline-block !important;
}

.inline-dropdown {
  display: inline-block !important;
  width: auto !important;
  margin-right: 10px !important;
}

.boxshaowstyle {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px !important;
}

.custom-width {
  width: 30% !important;
}

.list {
  flex: 0 0 20rem;
}

.list__item {
  position: relative;
}

.list__item:hover>.label {
  color: #009688;
}

.list__item:hover>.label::before {
  border: 0.4rem solid #4c5258;
  margin-right: 1rem;
  color: #2cc3a9 !important;
}

.radio-btn {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.radio-btn:checked+.label {
  color: #009688;
}

.radio-btn:checked+.label::before {
  margin-right: 1rem;
  border: 0.4rem solid #2cc3a9;
}

.label {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  /* color: #2cc3a9 !important; */
  font-size: 14px;
  cursor: pointer;
  transition: all 0.25s linear;
}

.label::before {
  display: inline-block;
  content: "";
  height: 20px;
  width: 20px;
  margin-right: 0.625rem;
  border: 0.4rem solid #4c5258;
  border-radius: 50%;
  transition: all 0.25s linear;
}

.boxshaowstyle-forComments {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}

.addcomments-box-style {
  border-radius: 5px;
  background: var(--background-color, #f7f8fa);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}

.chatheading {
  color: #495057;
  font-size: 14px;
  line-height: normal;
}

.chatheadingsubtitle {
  color: #878a99;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.chatcontent {
  color: #4c5258;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.addchatcontent {
  color: var(--low-opacity-color, rgba(21, 24, 33, 0.25));
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.comment-textarea {
  background-color: #f7f8fa;
  border: none;
  padding: 10px;
  resize: vertical;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.btns {
  background-color: #f7f8fa !important;
  color: #495057 !important;
  /* background-image: linear-gradient(100deg, rgb(255, 90, 100) 0%, rgb(250, 160, 75) 30%, rgb(210, 220, 105) 70%, rgb(14, 244, 155) 100%); */
  height: 50px;
  border-radius: 10px;
  box-shadow: 0 2px 4px -1px rgba(25, 25, 25, 0.2);
}

.btns input {
  display: none;
}

.btns label {
  display: table;
  float: left;
  padding: 0;
  width: 50%;
  height: 100%;
  margin: 0;
  text-align: center;
  border-left: 1px solid transparentize(#000, 0.95);
}

.btns label:first-child {
  /* border-left: none; */
  border-radius: 10px 0 0 10px !important;
}

.btns label:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.btnnew {
  font-size: 15px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  box-shadow: inset 0 30px 20px -20px rgba(255, 255, 255, 0.15);
  transition: color 250ms ease-out, box-shadow 250ms ease-in;
  -webkit-transition: color 250ms ease-out, box-shadow 250ms ease-in;
  -moz-transition: color 250ms ease-out, box-shadow 250ms ease-in;
}

.btnnew:hover {
  box-shadow: inset 0 30px 30px -20px rgba(50, 50, 50, 0.02);
  transition: all 250ms ease-out;
}

.btnnew:after {
  content: "\2714";
  margin-left: -10px;
  display: inline-block;
  transform: scale(0);
  transition: margin 250ms ease-in-back 100ms, transform 250ms ease-in;
}

input:checked+.btnnew {
  color: white;
  text-shadow: none;
  box-shadow: inset 0 10px 50px rgba(25, 25, 25, 0.08);
  transition: color 500ms ease-out, box-shadow 150ms;
  background-color: #2cc3a9;
}

input:checked+.btnnew:after {
  margin-left: 5px;
  transform: scale(1.25);
  transition: transform 500ms ease-out-back 250ms, margin 500ms;
}

.firstnew {
  border-radius: 10px 0 0 10px !important;
}

.lastnew {
  border-radius: 0 10px 10px 0 !important;
}

.supportticket-fontcolor {
  color: #4c5258;
}

.shipdevice-font-style {
  font-weight: 600;
  font-size: 14px;
  color: #4c525880;
}

.locations-Text {
  color: #9ea4aa;
  font-size: 14px;
}

.centerstyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title {
  color: #4c5258;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}

.small-desc {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  color: #4c5258;
}

.faqcard {
  font-family: "Poppins", sans-serif !important;
  display: block;
  position: relative;
  max-height: 320px;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 2em 1.2em;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.faqcard:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: linear-gradient(135deg, #3cbba5, #3cbba5);
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.35s ease-out;
}

.faqcard:hover:before {
  transform: scale(28);
}

.faqcard:hover .small-desc {
  transition: all 0.5s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.faqcard:hover .card-title {
  transition: all 0.5s ease-out;
  color: #ffffff;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2em;
  height: 2em;
  overflow: hidden;
  top: 0;
  right: 0;
  background: linear-gradient(135deg, #3cbba5, #3cbba5);
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: 0px;
  margin-right: -5px;
  color: white;
  font-family: courier, sans;
  transform: rotateZ(143deg);
  font-size: 20px;
}

.modal-title {
  font-size: 17px !important;
}

.planname-style {
  color: #3cbba5;
  font-size: 20px;
}

.content-forsetupcards {
  font-size: 13px;
  color: #4c5258;
}

.setup-status-square {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  border: 1px solid #e2e3e4;
}

.yellow-backgroung-style {
  background-color: #fdfae4;
}

.purple-backgroung-style {
  background-color: #eff4ff;
}

.green-backgroung-style {
  background-color: #e7fef6;
}

.pink-backgroung-style {
  background-color: #ffd3dd;
}

.rejected-backgroung-style {
  background-color: #f9bdb5;
}
.brown-backgroung-style {
  background-color: #ffe9e7;
}
.blue-backgroung-style {
  background-color: #c9ebf8;
}

.contract-confirm-font {
  font-size: 12px;
  color: #3cbba5;
  font-weight: 500;
}

.contract-details-font {
  font-size: 14px;
  color: #4c5258;
  font-weight: 500;
}

.total-price {
  position: absolute;
  bottom: 0;
}

.wordwrap {
  word-wrap: break-word;
}

.activePlanFilter {
  font-weight: 600;
}

.activePlanFilter>div {
  border: 2px solid;
}

.contract-heading {
  font-weight: 600;
  font-size: 32px;
  color: #030e20;
}

.contract-content-font {
  font-weight: 400;
  font-size: 16px;
  color: #4c5258;
}

.contract-charge-font {
  font-weight: 600;
  font-size: 18px;
  color: #3cbba5;
}

.GetStarted {
  background: #3cbba5;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border: 1px solid #3cbba5;
  color: white;
  padding: 7px 25px;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 10px !important;
}

.schoolLogo-background {
  position: absolute;
  background-color: rgb(231, 230, 230);
  height: 220px;
  width: 220px;
  border-radius: 30px;
  top: 287px;
  left: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes highlight {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.highlight-icon {
  animation: highlight 2s linear;
}

.whats-cover-text {
  color: #3cbba5;
}

.whats-cover-commontext {
  font-size: 24px;
  font-weight: 600;
}

.claim-process {
  font-size: 18px;
  font-weight: 600;
}

.claim-process-content {
  font-size: 14px;
  font-weight: 400;
}

.signup-font {
  font-size: 18px;
  color: #4f4f4f;
  font-weight: 600;
}

.contentClr {
  color: rgba(0, 0, 0, 0.5);
}

.zoom-effect {
  transition: transform 0.2s ease;
  color: rgb(59, 187, 164) !important;
  font-weight: bold;
}

.zoom-effect:hover {
  transform: scale(1.1);
  color: black;
}

.supportTicketbtn {
  background: #f9fafb;
  border: 1px solid #e2e3e4;
  border-radius: 15px 15px 15px 15px;
  padding: 8px;
}

.progressbarClass {
  position: unset !important;
  height: 11px !important;
  background: transparent !important;
  z-index: 2147483647 !important;
  width: 100%;
  border-radius: 6px !important;
  box-shadow: none !important;
}

.progressbarClass>div>div {
  box-shadow: none !important;
  width: 0 !important;
}

.admin-table-border-top {
  border-top: 1px solid rgb(222, 226, 230);
}

.dragMe {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.dragMe:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

/* Modal.css */
.modals {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.modals-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.3s ease-in-out;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

@keyframes slide-up {
  from {
    transform: translateY(50px);
  }

  to {
    transform: translateY(0);
  }
}

.modals.open {
  opacity: 1;
  pointer-events: auto;
}

.greyBoxShadow {
  background: #f7f8fa;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
}

.greyBoxShadow:hover {
  box-shadow: 0px 3px 15px 0px #c5c5c5;
}

.px-2-1 {
  padding-right: 2.1rem !important;
  padding-left: 2.1rem !important;
}

.dashboard-grayBox {
  background: #f7f8fa;
  border-radius: 10px;
  padding: 10px;
}

.progress-container {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}

.col-20 {
  flex: 0 0 auto;
  width: 20% !important;
}

.ticketCloser {
  border-radius: 0.375rem 0px 0px 0.375rem !important;
  padding: 7px 0 !important;
  background-color: #b7b7b7 !important;
  border-color: #b7b7b7 !important;
  font-size: 0.9rem !important;
  color: #000 !important;
}

.ticketCloserBtn {
  border-radius: 0px 0.375rem 0.375rem 0px !important;
  padding: 6px 0 !important;
  background-color: #3cbba5 !important;
  border-color: #3cbba5 !important;
  font-size: 0.9rem !important;
  color: #000 !important;
}

.ticketCloserBtn:hover {
  border-radius: 0px 4px 4px 0px !important;
  padding: 6px 0 !important;
  background-color: #00947a !important;
  border-color: #00947a !important;
  font-size: 0.9rem !important;
  color: #fff !important;
}

.borderNone .col-20 input {
  border-radius: 0px !important;
  background-color: #d9d9d9 !important;
}

.borderNone .col-20 input:focus {
  box-shadow: none !important;
}

.opacity-ghost {
  transition: all 0.18s ease;
  opacity: 0.8;
  transform: rotateZ(5deg);
  background-color: transparent;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.opacity-ghost-drop {
  opacity: 1;
  transform: rotateZ(0deg);
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0);
}

.chart-container {
  max-width: 100%;
  overflow-x: auto;
}

.chart-container .apexcharts-canvas {
  min-width: 100%;
}

.boxShadowStyle {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}

.boxStyle {
  border-radius: 4px;
  background: #fff;
}

.boxShadowStyle:hover {
  box-shadow: 0px 3px 15px 0px #3cbba5;
}

.boxShadowStyleGray {
  border-radius: 4px;
  background: #3cbba5;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}

.boxShadowStyleGray:hover {
  box-shadow: 0px 3px 15px 0px #3cbba5;
}

.boxShadowStyleBlack {
  border-radius: 4px;
  background: #000;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}

.boxShadowStyleBlack:hover {
  box-shadow: 0px 3px 15px 0px #737373;
}

.tabView {
  border-radius: 8px;
  background: #fff;
  border: 0.5px solid var(--filters, rgba(162, 162, 162, 0.8));
}

.tabView:hover {
  box-shadow: 0px 3px 15px 0px #3cbba5;
}

.filter-dropdown {
  position: relative;
}

.filter-dropdown-content {
  position: absolute;
  min-width: 850px;
  z-index: 9 !important;
  right: 0%;
  top: 130%;
}

.admin-filter-dropdown-content {
  position: absolute;
  z-index: 9 !important;
  right: 0%;
  top: 130%;
}

.admin-KPI-active {
  min-width: 900px;
}

.admin-table-active {
  min-width: 750px;
}

.arrange-dropdown {
  position: relative;
}

.arrange-dropdown-content {
  position: absolute;
  min-width: 500px;
  z-index: 9 !important;
  right: 0%;
  top: 130%;
}

.date-dropdown {
  position: relative;
}

.date-dropdown-content {
  position: absolute;
  min-width: 175px;
  z-index: 9 !important;
  right: 0%;
  top: 130%;
}

.Date-font {
  color: #000;
  font-size: 16px;
  font-weight: 550;
  cursor: pointer;
}

.Date-font:hover {
  color: #fff;
  background: #3cbba5;
}

.admin-hr {
  color: #aaa !important;
  border: 0;
  border-top: 3px solid;
  opacity: 0.7;
  margin-top: 10px;
  margin-bottom: 10px;
}

.filter-apply {
  padding: 6px 0 !important;
  background-color: #3cbba5 !important;
  border-color: #3cbba5 !important;
  font-size: 0.9rem !important;
  color: #000 !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
}

.filter-apply:hover {
  box-shadow: 0px 3px 4px 0px #3cbba5 !important;
}

.display-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.mobile {
  display: block;
}

@media screen and (max-width: 760px) {
  .filter-dropdown-content {
    min-width: 300px;
  }

  .mobile {
    display: none;
  }

  .admin-filter-dropdown-content {
    min-width: 175px !important;
  }

  .arrange-dropdown-content {
    min-width: 330px;
    left: 0%;
  }

  .SchoolContainDashboard {
    margin-top: 225px !important;
  }

  .AdminContainDashboard {
    margin-top: 11.5rem !important;
  }
}

.schoolDashboard {
  position: sticky;
  z-index: 9;
  top: 49px;
  background-color: #f3f3f3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.SchoolContainDashboard {
  margin-top: 135px;
  z-index: 0;
}

/* for set price button */
.set-price-btn {
  display: inline-block;
  padding: 2px 10px;
}

.set-price-btn:hover {
  border: 1.5px solid black;
  border-radius: 20px;
  padding: 2px 10px;
}

.set-price-btn span {
  text-decoration: underline;
  cursor: pointer;
}

.set-price-btn span:hover {
  text-decoration: none;
}

.zoom-in-out-box {
  margin: 24px;
  width: 20px;
  height: 20px;
  animation: zoom-in-zoom-out 1s ease forwards;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.AdminContainDashboard {
  margin-top: 5rem;
  z-index: 0;
}

.admin-dashboard-box-one {
  border-radius: 8px;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.79) -31.7%,
      #dcdcdc -31.7%,
      rgba(255, 255, 255, 0.2) 484.6%,
      #ababab 484.6%);
}

.admin-dashboard-box-one:hover {
  box-shadow: 2px 4px 20px 0px rgb(222 222 222);
  transform: translateY(-3px);
  transition: 0.5s;
}

.admin-dashboard-box-two {
  border-radius: 8px;
  background: linear-gradient(90deg,
      rgba(102, 255, 228, 0.4) 0%,
      rgba(135, 255, 234, 0.4) 39.69%,
      rgba(135, 254, 233, 0.4) 71.98%,
      rgba(118, 255, 231, 0.4) 100%,
      rgba(118, 255, 231, 0.4) 100%);
}

.admin-dashboard-box-two:hover {
  box-shadow: 2px 4px 20px 0px rgb(187 249 238);
  transform: translateY(-3px);
  transition: 0.5s;
}

.admin-dashboard-box-three {
  border-radius: 8px;
  background: linear-gradient(300deg,
      rgba(0, 255, 117, 0.6) 1.28%,
      rgba(0, 255, 117, 0.48) 48.48%,
      rgba(0, 222, 102, 0.36) 98.45%,
      rgba(0, 255, 117, 0.12) 134.54%);
}

.admin-dashboard-box-three:hover {
  box-shadow: 2px 4px 20px 0px rgb(106 255 174 / 94%);
  transform: translateY(-3px);
  transition: 0.5s;
}

.admin-dashboard-box-four {
  border-radius: 8px;
  background: radial-gradient(192.34% 139.42% at 98.64% 98.53%,
      rgba(52, 152, 219, 0.4) 0%,
      rgba(52, 152, 219, 0.38) 33.33%,
      rgba(52, 152, 219, 0.28) 66.67%,
      rgba(52, 152, 219, 0.34) 100%);
}

.admin-dashboard-box-four:hover {
  box-shadow: 2px 4px 20px 0px rgb(147 211 255);
  transform: translateY(-3px);
  transition: 0.5s;
}

.admin-dashboard-box-five {
  border-radius: 8px;
  background: #BAEBFF4D;

}

.admin-dashboard-box-four:hover {
  box-shadow: 2px 4px 20px 0px rgb(147 211 255);
  transform: translateY(-3px);
  transition: 0.5s;
}

.admin-dashboard-box-five:hover {
  box-shadow: 2px 4px 20px 0px rgb(178, 222, 252);
  transform: translateY(-3px);
  transition: 0.5s;
}

.admin-dashboard-box-fonts .text-one {
  font-size: 15px;
  font-weight: 600;
}

.admin-dashboard-box-fonts .text-two {
  font-size: 30px;
  font-weight: 500;
}

.FilterBorder {
  border-radius: 0.375rem !important;
}

/* for custom heder */
.column-header-box {
  border: 1px solid #b8b7b7;
  background-color: #f7f6f6;
  border-radius: 0.5em;
  padding: 8px 20px 8px 20px;
  cursor: move;
}

.customize-box-style {
  border: 1px solid #666;
}

.dragged-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #efeff0 !important;
  z-index: 1;
}

.boxone {
  text-shadow: 0px 1px 2px #3cbba5;
}

.boxtwo {
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

.boxthree {
  text-shadow: 0px 1px 2px rgba(16, 61, 92, 0.5);
}

.boxfour {
  text-shadow: 0px 1px 2px rgba(0, 103, 47, 0.3);
}

.boxfive {
  text-shadow: 0px 1px 2px rgb(80, 80, 80, 0.3);
}

.admin-dashboard-box-two .text-one {
  color: #00b697;
}

.admin-dashboard-box-one .text-one {
  color: #767676;
}

.admin-dashboard-box-four .text-one {
  color: #0f5b8e;
}

.admin-dashboard-box-five .text-one {
  color: #09658D;
}

.admin-dashboard-box-three .text-one {
  color: #00672f;
}

.SuggestionBoxDiv .suggestion-item.selected {
  background-color: #f2f2f2;
}

.highlight-part {
  color: #e5c20de8;
  font-weight: 600;
}

.invoce-modal-style {
  border: 2px solid #cacaca;
  padding: 10px;
}

.text-blue-billable {
  color: #03a9f4;
}

.text-gray-billable {
  color: #999;
}

.theme-colorcode {
  color: #36b09b;
  font-weight: 600;
}

.theme-color-batch {
  color: #36b09b;
}

.notificationdiv {
  max-height: 350px;
  overflow-y: scroll;
}

.progressbarClass.CustomProgressbar>div {
  background: repeating-linear-gradient(-45deg,
      #fff,
      #fff 10px,
      #3cbba5 10px,
      #3cbba5 20px) !important;
  animation: progressAnimation 2s linear infinite;
}

@keyframes progressAnimation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 20px 0;
  }
}

.remove-google-button {
  padding: 10px !important;
  border-radius: 16px !important;
}

.payment-container {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  justify-content: "center";
  height: "100vh";
}

.payment-icon-style {
  font-size: 100%;
  color: "green";
  margin-bottom: "20px";
}

.disabled-form {
  pointer-events: none;
  opacity: 0.6;
}

.label-style-receipt {
  color: gray;
  font-size: 13px;
}

.signatureCanvas {
  border: 2px dotted gray;
  border-radius: 5px;
}

.Payment-screen-style {
  background-color: #69d1b8;
}

.payment-header-style {
  background-color: #60cbb2;
  border-radius: 10px 10px 0px 0px;
}

.payment-schoolName-style {
  font-size: 14px;
  color: #e7f3b1;
}

.Go-to-text-style {
  color: #8a8a8a;
  font-size: 14px;
}

.go-to-content-style {
  color: #8a8a8a;
  font-size: 12px;
}

.GridBox-hover:hover {
  box-shadow: 0px 3px 15px 0px #b3b3b3;
}

.gtp-header-style {
  color: #6a6a6a;
  font-size: 16px;
}

.answerdiv {
  border: 1px solid #b2afaf;
  padding: 5px;
  border-radius: 5px;
  color: #6a6a6a;
  font-size: 16px;
}

/* help desk */
.maincategory {
  border: 1px solid #eaf8f5;
  padding: 7px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color: #eaf8f5;
  border-left: 5px solid #3cbba5;
  margin: 0 !important;
}

.subcategorydiv {
  border: 1px solid #e5eefe;
  padding: 7px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: #e5eefe;
  border-left: 5px solid #347af8;
}

.twodotdiv {
  border: 1px solid #fde8ef;
  padding: 7px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: #fde8ef;
  border-left: 5px solid #ed4e87;
}

.threedotdiv {
  border: 1px solid #fdefe8;
  padding: 7px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: #fdefe8;
  border-left: 5px solid #ed874e;
}

.AnswerBox {
  background: #ffffff;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.IssueBox {
  border: 1px solid #f6f6f6;
  border-radius: 19px;
  padding: 9px;
  width: 50%;
  background-color: aliceblue;
  color: #1e1e9a;
  font-size: 15px;
  font-weight: 500;
}

.HDDiv {
  background: rgba(52, 81, 106, 1);
  background: -moz-linear-gradient(top,
      rgba(52, 81, 106, 1) 0%,
      rgba(57, 177, 162, 1) 70%,
      rgba(57, 177, 162, 1) 100%);
  background: -webkit-linear-gradient(top,
      rgba(52, 81, 106, 1) 0%,
      rgba(57, 177, 162, 1) 70%,
      rgba(57, 177, 162, 1) 100%);
  background: -o-linear-gradient(top,
      rgba(52, 81, 106, 1) 0%,
      rgba(57, 177, 162, 1) 70%,
      rgba(57, 177, 162, 1) 100%);
  background: -ms-linear-gradient(top,
      rgba(52, 81, 106, 1) 0%,
      rgba(57, 177, 162, 1) 70%,
      rgba(57, 177, 162, 1) 100%);
  background: linear-gradient(to bottom,
      rgba(52, 81, 106, 1) 0%,
      rgba(57, 177, 162, 1) 70%,
      rgba(57, 177, 162, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#34516a', endColorstr='#39b1a2', GradientType=0);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 100%;
  padding: 30px;
  color: white;
  max-height: 100%;
}

.HdBtnDiv {
  color: #3cbba5;
  font-weight: 600;
}

.categoryDivs {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px !important;
  border-radius: 5px;
  background: #3cbba512;
  font-size: 13px;
}

.helpdesklogo {
  height: 75px;
  width: 75px;
}

.helpDesk-button {
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #3cbba5;
  background: white;
  color: #3cbba5;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Backbtn {
  position: absolute;
  right: -7px;
  top: 17px;
}

.helpDesk-EndThread {
  border-radius: 10px;
  padding: 6px;
  border: 1px solid #3cbba5;
  box-shadow: 0px 4px 4px 0px #20726499;
  background: #3cbba5;
  font-size: 15px;
}

.helpDesk-button.active {
  border: 1px solid #3cbba5;
  background: #3cbba5;
  color: white;
  border-top-left-radius: 0px;
}

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dot {
  height: 5px;
  width: 5px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3d4fc;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #000;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #000;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #000;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}

.questionchatbox {
  background-color: #fcfcfc;
  border: 1px solid #cbd6e2;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  border-top-left-radius: 0px;
  font-size: 14px;
  text-align: center;
  position: relative;
}

.questionlogo {
  position: absolute;
  left: -9px;
  top: -8px;
  height: 22px;
  width: 22px;
}

.helpDesk_schoolName {
  font-size: 30px;
  background: linear-gradient(90deg, #3cbba5 0%, #1e1e1e 100%);
  -webkit-background-clip: text;
  /* WebKit/Blink browsers */
  background-clip: text;
  /* Standard property for better compatibility */
  color: transparent;
  display: inline-block;
}

.helpDesk-label {
  border-radius: 0px 12px 12px 12px;
  background-color: #f5f5f5;
  border: 1px solid #d0d0d0;
  padding: 6px;
}

.helpDesk-undo {
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 27px !important;
  cursor: pointer;
}

.helpdeskgreyBox {
  background-color: #f3f3f3;
  border-radius: 10px;
}

.table> :not(caption)>* {
  background-color: transparent;
}

.table {
  color: initial;
  background-color: initial;
  border-color: initial;
}

.table>:not(caption)>*>* {
  border-bottom-width: 1px !important;
  color: unset !important;
  background-color: transparent !important;
}

#Description .ql-container,
.Description .ql-container {
  height: 30vh;
}

.img-icon {
  height: 20px;
  width: 20px !important;
  line-height: 20px;
  border-radius: 50%;
}

.categoryimg-icon {
  height: 30px;
  width: 30px !important;
  border-radius: 50%;
}

.ql-container.ql-snow {
  height: 100px;
}

.helpdesk-container {
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
}

@keyframes byebye {

  0%,
  20%,
  40%,
  60%,
  80% {
    transform: translateY(-3px) rotate(15deg);
  }

  10% {
    transform: translateY(-3px) rotate(-15deg);
  }

  30% {
    transform: translateY(-3px) rotate(15deg);
  }

  50% {
    transform: translateY(-3px) rotate(-15deg);
  }

  70% {
    transform: translateY(-3px) rotate(15deg);
  }

  90% {
    transform: translateY(-3px) rotate(-15deg);
  }

  100% {
    transform: translateY(-3px) rotate(15deg);
  }
}

.byebye-animation {
  animation: byebye 1.5s infinite;
}

.form-check-input {
  border-color: #909090 !important;
}

.UnSelected-div-style {
  background: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.Selected-div-style {
  background: #3cbba5;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 14%);
  color: white;
}

.color-828282 {
  color: #828282;
}

.gradebox-style {
  background-color: #3cbba5;
  border-radius: 4px;
  color: white;
}

.footerlabel:hover {
  text-decoration: underline;
  cursor: pointer;
}

.smallicon {
  height: 25px;
  width: 25px;
}

/* inventory audit css */
.audit-info-div {
  background-color: #3CBBA5;
  border-radius: 17px;
  color: white;
}

.UploadCSVBtn-for-audit {
  background: #6ac7b6;
  border: 1px solid #3CBBA5;
  border-radius: 10px;
  padding: 8px;
  color: white;
}

.option-audit-div {
  background: #e8e8e8;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 22%);
}

.font-17 {
  font-size: 17px;
}


.confirmation-box {
  background-color: #ededed;
  color: black;
  border-radius: 10px;
  padding: 11px;
  font-size: 13px;
}

.btn-no {
  background-color: #ededed;
  border: none;
  color: black;
  border: 1px solid #bababa;
  border-radius: 10px;
  height: 35px;
}

.btn-yes {
  background-color: #3CBBA5;
  border: none;
  color: white;
  border-radius: 10px;
  height: 35px;
}

.auditbackground {
  background: linear-gradient(to bottom, #e0f7fa, #ffffff);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.auditTitles {
  border: 1px solid #3CBBA5;
  background: white;
  padding: 10px 15px;
  border-radius: 5px;
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.scale-in-animation {
  animation: scaleIn 2s ease-out infinite;
}

.blueText {
  color: blue;
}

.gradeselect:disabled {
  background-color: #e9ecef;
}
