.manage-button {
  background: #3cbba5;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border: 1px solid #3cbba5;
  color: white;
  padding: 6px 10px;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 4px !important;
  text-align: center;
}

.greyColor {
  color: gray;
}